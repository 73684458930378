<template>
  <div>
    <b-row>
      <b-col cols="12">
        <BaseCard
          title="informe de no conformidad"
          :loading="loading"
          :footer-visible="false"
          collapsable
        >
          <NonConformityProfileForm
            ref="non-conformity-profile-form"
            v-model="nonConformity"
          />
        </BaseCard>
      </b-col>
      <b-col cols="12">
        <BaseCard
          title="Descripción"
          collapsable
          :footer-visible="false"
          :loading="loading"
        >
          <NonConformityDescriptionForm v-model="nonConformity" />
        </BaseCard>
      </b-col>
      <b-col cols="12">
        <BaseCard
          title="Análisis y Causas"
          collapsable
          :footer-visible="false"
          :loading="loading"
        >
          <NonConformityCauseForm
            ref="non-conformity-cause-form"
            v-model="nonConformity"
          />
        </BaseCard>
      </b-col>
      <b-col cols="12">
        <BaseCard
          title="Resultado de la no conformidad"
          :loading="loading"
          :caption-visible="false"
          :footer-visible="false"
          collapsable
        >
          <NonConformityResultForm
            ref="non-conformity-result-form"
            v-model="nonConformity"
          />
        </BaseCard>
      </b-col>
      <b-col cols="12">
        <BaseCard
          title="Acción correctiva / preventiva"
          :loading="loading"
          :caption-visible="false"
          :footer-visible="false"
          collapsable
        >
          <NonConformityCorrectiveActionForm
            ref="non-conformity-corrective-action-form"
            v-model="nonConformity"
          />
        </BaseCard>
      </b-col>
      <b-col cols="12">
        <BaseCard
          title="Resultado de la acción correctiva / preventiva"
          :loading="loading"
          :caption-visible="false"
          :footer-visible="false"
          collapsable
        >
          <NonConformityCorrectiveActionResultForm
            ref="non-conformity-corrective-action-result-form"
            v-model="nonConformity"
          />
        </BaseCard>
      </b-col>
    </b-row>
    <b-row
      align-v="center"
      align-h="end"
    >
      <b-col cols="auto">
        <b-button
          variant="primary"
          class="text-nowrap rounded-sm px-2"
          :disabled="loading"
          @click="handleSaveButtonClick"
        >
          Guardar
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import NonConformitiesApi from '@/api/non-conformities-api'
import JobsApi from '@/api/jobs-api'
import BaseCard from '@/components/ui/card/BaseCard.vue'
import NonConformityProfileForm from '@/components/non-conformities/form/NonConformityProfileForm.vue'
import NonConformityResultForm from '@/components/non-conformities/form/NonConformityResultForm.vue'
import NonConformityCorrectiveActionForm from '@/components/non-conformities/form/NonConformityCorrectiveActionForm.vue'
import NonConformityCorrectiveActionResultForm from '@/components/non-conformities/form/NonConformityCorrectiveActionResultForm.vue'
import NonConformityDescriptionForm from '@/components/non-conformities/form/NonConformityDescriptionForm.vue'
import NonConformityCauseForm from '@/components/non-conformities/form/NonConformityCauseForm.vue'

export default {
  components: {
    NonConformityCauseForm,
    NonConformityDescriptionForm,
    NonConformityCorrectiveActionResultForm,
    NonConformityCorrectiveActionForm,
    NonConformityResultForm,
    NonConformityProfileForm,
    BaseCard,
  },
  data() {
    return {
      loading: false,
      nonConformity: {},
    }
  },
  computed: {
    jobId() {
      return this.$route.query['job-id'] || null
    },
    nonConformityProfileForm() {
      return this.$refs['non-conformity-profile-form']
    },
    nonConformityResultForm() {
      return this.$refs['non-conformity-result-form']
    },
    nonConformityCauseForm() {
      return this.$refs['non-conformity-cause-form']
    },
    nonConformityCorrectionForm() {
      return this.$refs['non-conformity-corrective-action-form']
    },
  },
  mounted() {
    this.loadJob()
  },
  methods: {
    async loadJob() {
      if (!this.jobId) {
        return
      }

      this.loading = true
      try {
        const response = await JobsApi.get(this.jobId)
        this.nonConformity = { ...this.nonConformity, job: response.data }
      } finally {
        this.loading = false
      }
    },
    async handleSaveButtonClick() {
      const profileValid = await this.nonConformityProfileForm.validateForm()
      const resultValid = await this.nonConformityResultForm.validateForm()
      if (!profileValid || !resultValid) {
        return
      }

      this.loading = true
      try {
        const { documentation } = this.nonConformityProfileForm.getDocumentationData()
        const { images_causes, images_action } = this.nonConformityCauseForm.getImagesData()
        const { images_correction } = this.nonConformityCorrectionForm.getImagesData()

        const response = await NonConformitiesApi.create({
          documentation, images_causes, images_action, images_correction, ...this.nonConformity,
        })

        await this.$router.replace({ name: 'viewNonConformity', params: { id: response.data.id } })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
</style>
